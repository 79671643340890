import "bootstrap"
import React from "react"
import classNames from 'classnames'
import { createGlobalStyle } from "styled-components"
import {AmnestyTradeGothic, AmnestyTradeGothicBd2, AmnestyTradeGothicLight} from "../../utils/fonts";
import Footer from "./footer"
import Header from "./header"
import Newsletter from "./newsletter"

const GlobalStyle = createGlobalStyle`
  // Light font
  @font-face {
    font-family: "Amnesty Trade Gothic";
    src: url(${AmnestyTradeGothicLight});
    font-weight: 300;
    font-style: normal;
  }
  // Regular font
  @font-face {
    font-family: "Amnesty Trade Gothic";
    src: url(${AmnestyTradeGothic});
    font-weight: normal;
    font-style: normal;
  }
  // Bold font
  @font-face {
    font-family: "Amnesty Trade Gothic";
    src: url(${AmnestyTradeGothicBd2});
    font-weight: 600;
    font-style: normal;
  }
`

const Layout = ({ children, page }) => {
  return (
    <div id="app" className={classNames([
      'page-layout',
      page !== null && `${page}-page`
    ])}>
      <GlobalStyle />
      <Header/>
      <main>
        {children}
        <Newsletter/>
        <Footer/>
      </main>
    </div>
  )
}

Layout.defaultProps = {
  page: null
}

export default Layout