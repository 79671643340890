import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const content = getContent()
  const {
    cta,
    sub_title,
    title,
    url
  } = content.componentsJson

  return (
    <section className="container-fluid bg-light candle-background" style={{
        backgroundImage: `url("${content.candle.childImageSharp.fluid.src}")`
      }}
    >
      <div className="container text-center py-5">
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase font-weight-bold">
              {title}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 pt-2">
            <p className="font-weight-light">
              {sub_title}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 py-5">
            <a href={url} className="btn btn-primary btn-lg">
              {cta}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

const getContent = () => {
  return useStaticQuery(
    graphql`
      query {
        componentsJson(id: { eq: "newsletter" }) { 
          cta,
          sub_title,
          title,
          url
        },
        candle: file(name: { eq: "Amnesty_candle_RGB_White" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
    `
  )
}