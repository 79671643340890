import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const KeepRatio = ({ children, width, height, className, holderClassName }) => {
    const styles = {
        paddingTop: `${height * 100 / width}%`
    };

    return (
        <div className={classNames([
            'keep-ratio',
            `ratio-${width}-${height}`,
            className !== '' && className
        ])} style={styles}>
            <div className={classNames([
                'keep-ratio-holder',
                holderClassName !== '' && holderClassName
            ])}>
                {children}
            </div>
        </div>
    )
};

KeepRatio.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    holderClassName: PropTypes.string,
    className: PropTypes.string
}

KeepRatio.defaultProps = {
    width: 1,
    height: 1,
    holderClassName: '',
    className: ''
}

export default KeepRatio;