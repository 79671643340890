import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
// import Img from "gatsby-image"
import PropTypes from "prop-types"
import ReactHoverObserver from 'react-hover-observer';
import KeepRatio from './keepRatio';

const Stories = ({ margin, profileId }) => {
  const stories = getStories(profileId)
  const profiles = stories.allProfilesJson.edges
  const {
    alternative_title,
    prejudice_cta,
    prejudice_title,
    title
  } = stories.componentsJson

  return (
    <section className={`${(margin) ? `my-${margin}` : ''} stories-section container-fluid bg-white p-1`}>
      <div className="row no-gutters">
          <div className="story-card col-12 col-md-4 p-1">
            <KeepRatio width={1} height={1}>
              <div className="bg-primary w-100 h-100 d-flex justify-content-center align-items-center">
                <p className='display-4 font-weight-bold text-center text-uppercase'>{(profileId) ? alternative_title : title}</p>
              </div>
            </KeepRatio>
          </div>
          {profiles.map(({node}, index) => (
            (profileId === node.fields.slug) ?
              '' : (
                <ReactHoverObserver className="story-card col-12 col-md-4 p-1" key={index}>
                  {({ isHovering }) => (
                      <Link to={`${node.fields.slug}`}>
                        <KeepRatio width={1} height={1}>
                          <ActionCard hovering={isHovering} image={node.image_action} />
                          <BlackCard 
                            first_name={node.first_name}
                            hovering={isHovering}
                            image={node.image_black}
                            last_name={node.last_name}
                            profession={node.profession}
                          />
                        </KeepRatio>
                      </Link>
                    )}
                </ReactHoverObserver>
              )
          ))}
          {(profileId) ? (
            <div className="story-card col-12 col-md-4 p-1">
              <KeepRatio width={1} height={1}>
                <div className="bg-dark text-white w-100 h-100 d-flex justify-content-center align-items-center text-center">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <p className="display-4">
                          {prejudice_title}
                        </p>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-12">
                        <Link to={`/prejuges`} className="btn btn-primary p-3">
                          {prejudice_cta}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </KeepRatio>
            </div>
          ) : ''}
        </div>
    </section>
  )
}

const ActionCard = ({hovering, image}) => (
  /*<Img fluid={image.childImageSharp.fluid} />*/
  <div className={`${(hovering) ? 'hide' : ''} box d-none d-md-block`}
     style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url("${image.childImageSharp.fluid.src}")`,
        backgroundSize: 'cover'
      }}
    >
  </div>
)

const BlackCard = ({first_name, hovering, image, last_name, profession}) => (
  <div className={`${(hovering) ? '' : 'hide'} box text-dark text-uppercase d-flex flex-column flex-md-row align-items-start align-items-md-end justify-content-end justify-content-md-between position-absolute`}
       style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
         backgroundImage: `url("${image.childImageSharp.fluid.src}")`, 
         backgroundSize: 'cover'
      }}
  >
    <div className="pb-0 pb-md-2 px-2">
      <div className="d-block bg-primary px-2">
        <h4 className="d-none d-md-block font-weight-bold">
          {first_name}
        </h4>
        <h4 className="d-block d-md-none font-weight-bold">
          {first_name} {" "} {last_name}
        </h4>
      </div>
      <div className="d-none d-md-block bg-primary px-2 mt-2">
        <h4 className="font-weight-bold">
          {last_name}
        </h4>
      </div>
    </div>
    <div className="pb-2 px-2">
      <div className="d-block bg-white px-2 mb-2 font-weight-bold">
        {profession}
      </div>
    </div>
  </div>
)

const getStories = (profileId) => {
  const data = useStaticQuery(
    graphql`
      query {
        componentsJson(id: { eq: "stories" }) {
          alternative_title,
          prejudice_cta,
          prejudice_title,
          title
        },
        allProfilesJson(sort: {fields: id, order: ASC}) {
          edges {
            node {
              fields {
                slug
              },
              first_name,
              image_action,
              image_black,
              last_name,
              profession
            }
          }
        },
        allFile(filter:{ relativePath: { regex: "/^images/profiles/" } }) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid,
                  originalName,
                  src
                }
              }
            }
          }
        }
      }
    `
  )

  data.allProfilesJson.edges.map(({node}) => {
    if (typeof node.image_action === 'string') {
      node.image_action = data.allFile.edges.find((image) => {
        return image.node.childImageSharp.fluid.originalName === node.image_action
      }).node
    }
    if (typeof node.image_black === 'string') {
      node.image_black = data.allFile.edges.find((image) => {
        return image.node.childImageSharp.fluid.originalName === node.image_black
      }).node
    }
    return node
  })

  return data
}

Stories.propTypes = {
  profileId: PropTypes.string,
}

export default Stories