import React, { useState, useEffect } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"

export default () => {
    const {
        allProfilesJson,
        componentsJson,
        logo,
        mobileLogo
    } = getNavs();
    const [scrollDirection, setScrollDirection] = useState('up');
    const [currentScrollY, setCurrentScrollY] = useState(0);
    const topThreshold = 150; // Will be hidden if user scrolls more than 150px
    let lastScrollY = 0;

    function handleScroll() {
        
        if(window.scrollY < topThreshold || window.scrollY < lastScrollY) {
            setScrollDirection('up');
        } 
        else {
            setScrollDirection('down');
        }
        
        lastScrollY = window.scrollY;
        setCurrentScrollY(lastScrollY);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        // willUnmount
        return () => window.removeEventListener('scroll', handleScroll)
    }, []); // didMount
    
    return (
        <nav className={classNames([
            "main-header navbar navbar-light navbar-expand-md fixed-top",
            scrollDirection === 'up' ? 'show' : 'hide',
            currentScrollY > topThreshold * 1.5 ? 'is-sticky bg-dark' : 'bg-transparent'
        ])}>
            <Link to={`/`} className="d-none d-md-block nav-link navbar-brand logo p-0">
                <Img fluid={logo.childImageSharp.fluid} />
            </Link>
            <Link to={`/`} className="d-block d-md-none nav-link navbar-brand logo col-2 p-0">
                <Img fluid={mobileLogo.childImageSharp.fluid} />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon icon={faBars} className='icon' size='2x' />
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav ml-auto mt-2 mt-md-0">
                <li className="nav-item dropdown">
                    <button className="btn btn-link text-light nav-link dropdown-toggle px-3 text-uppercase" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                        {componentsJson.navs.profiles}
                    </button>
                    <div className="dropdown-menu w-100">
                    {allProfilesJson.edges.map(({node}, index) => (
                        <Link to={`${node.fields.slug}`} className="text-uppercase dropdown-item text-dark text-left" key={index}>
                            {node.name}
                        </Link>
                    ))}
                    </div>
                </li>
                <li className="nav-item px-3">
                    <Link to={`/prejuges/`} className="nav-link text-light text-uppercase">
                        {componentsJson.navs.prejudices}
                    </Link>
                </li>
                </ul>
            </div>
        </nav>
    )
}

const getNavs = () => {
  return  useStaticQuery(
    graphql`
      query {
        componentsJson(id: { eq: "header" }) {
          navs {
            prejudices,
            profiles
          }
        },
        allProfilesJson(sort: {fields: id, order: ASC}) {
          edges {
            node {
              fields {
                slug 
              },
              name
            }
          }
        },
        logo: file(name: { eq: "FR_Amnesty_logo_RGB_yellow" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        },
        mobileLogo: file(name: { eq: "Amnesty_candle_RGB_Yellow" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
}