import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

export default () => {
  const {
    componentsJson,
    logo
  } = getContent()
  const {
    address,
    contact_us,
    copyright,
    main_site,
    phone,
    social_media
  } = componentsJson

  return (
    <footer className="container-fluid pt-3 pt-md-4 pb-2 bg-dark">
      <div className="container text-white">
        <div className="row text-center text-md-left">
          <div className="col-8 offset-2 py-3 col-md-3 py-md-0 offset-md-1">
            <div className="col-12 col-md-8 mb-3">
              <a href={main_site.url}>
                <Img fluid={logo.childImageSharp.fluid} />
              </a>
            </div>
            <div className="col-12">
              <OutboundLink
                className="small text-white"
                href={main_site.url}
              >
                {main_site.text}
              </OutboundLink>
            </div>
          </div>
          <div className="col-12 py-3 col-md-3 py-md-0 offset-md-5">
            <p className="text-uppercase font-weight-bold">
              {contact_us}
            </p>
            <ul className="list-unstyled small">
              <li>{address}</li>
              <li>{phone}</li>
            </ul>
            <div className="col-5 offset-4 offset-md-0 pl-0 d-flex justify-content-between">
              <a href={social_media.facebook} target="_blank" rel="noopener noreferrer" className="text-white" title="facebook">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href={social_media.instagram} target="_blank" rel="noopener noreferrer" className="text-white" title="instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href={social_media.twitter} target="_blank" rel="noopener noreferrer" className="text-white" title="twitter">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
          </div>
          <div className="col-12 py-3 py-md-2 text-center">
            <small className="d-block">
              {copyright}
            </small>
          </div>
        </div>
      </div>
    </footer>
  )
}

const getContent = () => {
  return useStaticQuery(
    graphql`
      query {
        componentsJson(id: { eq: "footer" }) { 
          address,
          contact_us,
          copyright,
          main_site {
            text,
            url
          }
          phone,
          social_media {
            facebook,
            instagram,
            twitter
          }
        },
        logo: file(name: { eq: "FR_Amnesty_logo_RGB_yellow" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
}